<template>
    <div>
        <!--begin::Card-->
        <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header border-0 py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{ $t("PRODUCT.QRCHARGE") }}
                    </h3>

                    <span
                        class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{
                            $tc("PRODUCT.SHOW_COUNT2", qrcharge.length, {
                                total: qrcharge.length,
                            })
                        }}</span
                    >
                </div>
                <div class="card-toolbar">
                    <router-link
                        :to="{ name: 'qrcharges', params: { id: 'new' } }"
                    >
                        <b-button variant="primary">{{
                            $t("QRCHARGE.NEW")
                        }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="qrcharge.length">
                    <table
                        class="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_4"
                    >
                        <thead>
                            <tr class="text-left">
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_NAME") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_CREATE") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_ORDER_NUMBER") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_MANUFACTURER") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QRC_COMMENT") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_AMOUNT") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_USED") }}
                                </th>
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.QR_EXPORT") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="qrcharge in paginatedqrcharge">
                                <tr v-bind:key="qrcharge.id">
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'qrcharges',
                                                params: {
                                                    id: qrcharge.id,
                                                    edit: true,
                                                },
                                            }"
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ qrcharge.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{
                                                check(qrcharge.creation_date)
                                            }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ qrcharge.order_number }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ qrcharge.mname }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ qrcharge.comment }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                        >
                                            <router-link
                                                :to="{
                                                    name: 'qrcodes',
                                                    params: { id: qrcharge.id },
                                                }"
                                                class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                            >
                                                {{ qrcharge.amount }}
                                            </router-link>
                                        </span>
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                        >
                                            {{ qrcharge.used }}
                                        </span>
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'qrcharges',
                                                params: {
                                                    id: qrcharge.id,
                                                    edit: true,
                                                },
                                            }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/Communication/Write.svg"
                                                    :title="$t('EDIT')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </router-link>
                                        <a
                                            v-if="qrcharge.used == 0"
                                            v-on:click="
                                                confirmDelete(qrcharge.id)
                                            "
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/General/Trash.svg"
                                                    :title="$t('DELETE')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <b-pagination
                        v-if="qrcharge.length > pagination.per_page"
                        class="pt-5"
                        v-model="pagination.current_page"
                        :total-rows="qrcharge.length"
                        :per-page="pagination.per_page"
                        align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>

            <!--end::Body-->
        </div>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Swal from "sweetalert2"

export default {
    name: "qrcharge",
    data() {
        return {
            qrexport: [],
            qrcharge: [],
            alert: {
                show: false,
                message: "",
                variant: "",
            },
            pagination: {
                current_page: 1,
                per_page: 20,
            },
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [])
        ApiService.get("qrcharge")
            .then(({ data }) => {
                this.qrcharge = data
            })
            .catch(() => {
                //Just keep it empty
            })
    },
    methods: {
        confirmDelete: function (id) {
            const product_index = this.qrcharge.findIndex(
                (qrcharge) => qrcharge.id === id
            )
            const product = this.qrcharge.find((qrcharge) => qrcharge.id === id)
            Swal.fire({
                title: this.$t("QRCHARGE.DELETE_TITLE", { name: product.name }),
                text: this.$t("QRCHARGE.DELETE_TEXT"),
                icon: "error",
                showCancelButton: true,
                cancelButtonText: this.$t("CANCEL"),
                confirmButtonText: this.$t("DELETE"),
                buttonsStyling: false,
                customClass: {
                    cancelButton: "btn btn-secondary",
                    confirmButton: "btn btn-danger",
                },
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete("qrcharge/" + id)
                        .then(() => {
                            this.qrcharge.splice(product_index, 1)
                            this.showAlert(
                                this.$t("QRCHARGE.DELETE_SUCCESS", {
                                    name: product.name,
                                }),
                                "success"
                            )
                        })
                        .catch(() => {
                            this.showAlert(
                                this.$t("QRCHARGE.DELETE_FAILURE", {
                                    name: product.name,
                                }),
                                "danger"
                            )
                        })
                }
            })
        },

        formatDate(dateString) {
            var date = dateString
            var result = " "
            if (date != null) {
                result =
                    "" +
                    date.getFullYear() +
                    (date.getMonth() + 1 > 9 ? "" : "0") +
                    (date.getMonth() + 1) +
                    (date.getDate() > 9 ? "" : "0") +
                    date.getDate()
            }
            return result
        },

        check(input) {
            var datum = new Date(input)
            const formatter = new Intl.DateTimeFormat("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            })
            const formattedDate = formatter.format(datum)
            return formattedDate // datum.getDate() + '.' + datum.getMonth() + '.' + datum.getFullYear() ;
        },

        downloadCSVData: function (id) {
            ApiService.get("qrcodes/export/" + id)
                .then(({ data }) => {
                    this.qrexport = data
                })
                .catch(() => {
                    //Just keep it empty
                })
                .then(() => {
                    let csv = "Url\n"
                    this.qrexport.forEach((row) => {
                        // csv += row.join(',');
                        csv += "https://s.xiphoo.com/?QID=" + row.code
                        csv += "\n"
                    })

                    const anchor = document.createElement("a")
                    anchor.href =
                        "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
                    anchor.target = "_blank"
                    anchor.download = "qrcodes.csv"
                    anchor.click()
                })
        },

        showAlert(text, variant) {
            window.scrollTo(0, 0)
            this.alert.text = text
            this.alert.variant = variant
            this.alert.show = true
            setTimeout(() => (this.alert.show = false), 5000)
        },
    },
    computed: {
        paginatedqrcharge() {
            const start =
                    (this.pagination.current_page - 1) *
                    this.pagination.per_page,
                end = start + this.pagination.per_page
            return this.qrcharge.slice(start, end)
        },
    },
}
</script>
